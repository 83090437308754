import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Disbursements | How revenue works",
  "includeToc": true,
  "description": "After collecting revenue from natural resource extraction, the Office of Natural Resources Revenue distributes that revenue to different agencies, funds, and local governments. This process is called “disbursement.”",
  "tags": ["How revenue works", "disbursements", "GOMESA", "Land and Water Conservation Fund", "Historic Preservation Fund", "Reclamation Fund", "Treasury", "revenue", "grants", "National Park Service"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GlossaryTerm = makeShortcode("GlossaryTerm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/how-revenue-works/"
      }}>{`How revenue works /`}</a></p>
    <h1 {...{
      "id": "Disbursements",
      "style": {
        "position": "relative"
      }
    }}>{`Disbursements`}</h1>
    <p>{`After collecting revenue from natural resource production, the Office of Natural Resources Revenue distributes that revenue. Recipients include federal agencies, funds, and local governments. This process is called "disbursement."`}</p>
    <br />
    <p>{`ONRR disburses revenue according to `}<a parentName="p" {...{
        "href": "/how-revenue-works/federal-laws/"
      }}>{`federal laws and regulations`}</a>{`. These laws and regulations govern how, when, and to whom disbursements are paid.`}</p>
    <h2 {...{
      "id": "Disbursement-recipients",
      "style": {
        "position": "relative"
      }
    }}>{`Disbursement recipients`}</h2>
    <p>{`ONRR disburses revenue to six main funds, recipients, or groups:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`U.S. Treasury`}</strong>{`: The federal government’s basic operating fund pays for roughly two-thirds of all federal expenditures. These include the military, national parks, and schools.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`State and local governments`}</strong>{`: Funds disbursed to states fall under the jurisdiction of each state. Each state determines how they will use the funds disbursed to them.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Reclamation Fund`}</strong>{`: This fund supports critical infrastructure projects like dams and power plants. `}<a parentName="p" {...{
            "href": "/how-revenue-works/reclamation"
          }}>{`How the Reclamation Fund works`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Native American tribes and individuals`}</strong>{`: ONRR disburses 100% of revenue collected from resource extraction on Native American lands back to tribes, nations, and individuals.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Land and Water Conservation Fund`}</strong>{`: This fund provides matching grants to states and local governments. They use these grants to buy and develop public outdoor recreation areas throughout the country. `}<a parentName="p" {...{
            "href": "/how-revenue-works/lwcf"
          }}>{`How the Land and Water Conservation Fund works`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Historic Preservation Fund`}</strong>{`: This fund helps preserve U.S. historical sites, archaeological sites, and cultural heritage. The fund provides grants to state and tribal historic preservation offices. `}<a parentName="p" {...{
            "href": "/how-revenue-works/hpf"
          }}>{`How the Historic Preservation Fund works`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Other funds`}</strong>{`: Some funds go back to federal land management agencies to help cover operational costs or special projects. "Other funds" also include disbursements to the Ultra-Deepwater Research Program and the Mescal Settlement Agreement.`}</p>
      </li>
    </ul>
    <p>{`We share `}<a parentName="p" {...{
        "href": "/query-data?dataType=Disbursements"
      }}>{`disbursement data for each fund by fiscal year`}</a>{`.`}</p>
    <h2 {...{
      "id": "Disbursements-and-location",
      "style": {
        "position": "relative"
      }
    }}>{`Disbursements and location`}</h2>
    <p>{`The distribution of disbursements often depends on the geographic source of the revenue. The largest geographic categories that govern disbursements are `}<em parentName="p">{`onshore`}</em>{` and `}<em parentName="p">{`offshore`}</em>{`.`}</p>
    <h3 {...{
      "id": "Disbursements-from-onshore-revenue",
      "style": {
        "position": "relative"
      }
    }}>{`Disbursements from onshore revenue`}</h3>
    <p>{`Leases on federal land within a given state or on Native American land generate onshore revenue.`}</p>
    <br />
    <p>{`Except for Alaska, states receive 50% of extractive revenue paid to the federal government from leases on federal land in that state. Alaska receives 90%, dating back to the Alaska Statehood Act.`}</p>
    <br />
    <p>{`40% of onshore revenue go to the Reclamation Fund. These funds are to be used for infrastructure projects, such as dams and power plants. 10% goes to the general fund of the U.S. Treasury, where Congress can appropriate it for programs and services. 2% of the above revenue are withheld and used for administrative purposes.`}</p>
    <h4 {...{
      "id": "Native-American-disbursements",
      "style": {
        "position": "relative"
      }
    }}>{`Native American disbursements`}</h4>
    <p>{`ONRR disburses 100% of revenue collected from resource extraction on Native American lands back to tribes, nations, and individuals. `}<a parentName="p" {...{
        "href": "/how-revenue-works/#how-are-natural-resources-on-native-american-land-governed"
      }}>{`Learn more about natural resources on Native American land`}</a>{`.`}</p>
    <h4 {...{
      "id": "State-and-county-level-disbursements",
      "style": {
        "position": "relative"
      }
    }}>{`State and county level disbursements`}</h4>
    <p>{`In most cases, each state determines how to use the funds disbursed to them. ONRR disburses funds directly to counties if they come from the `}<a parentName="p" {...{
        "href": "/how-revenue-works/gomesa/"
      }}>{`Gulf of Mexico Energy Security Act (GOMESA)`}</a>{` or from geothermal revenue.`}</p>
    <h3 {...{
      "id": "Disbursements-from-offshore-revenue",
      "style": {
        "position": "relative"
      }
    }}>{`Disbursements from offshore revenue`}</h3>
    <p>{`Most offshore areas are under the jurisdiction of the federal government. As a result, offshore revenue makes up the majority of disbursements to the U.S. Treasury. All the funds disbursed to The Land and Water Conservation Fund and Historic Preservation Fund come from offshore revenue.`}</p>
    <br />
    <p>{`A share of revenue generated from offshore leases within three miles of a given state's shoreline goes to that state. This three-mile region is called the `}<GlossaryTerm mdxType="GlossaryTerm">{`8(g)`}</GlossaryTerm>{` zone.`}</p>
    <br />
    <p>{`Most offshore revenue comes from oil and gas production in the Gulf of Mexico. The `}<a parentName="p" {...{
        "href": "/how-revenue-works/gomesa/"
      }}>{`Gulf of Mexico Energy Security Act (GOMESA)`}</a>{` of 2006 requires sharing a portion of revenue from resources in the Gulf of Mexico with four states that border the Gulf of Mexico. These states are Alabama, Louisiana, Mississippi, and Texas.`}</p>
    <h2 {...{
      "id": "Revenue-and-disbursement-totals",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue and disbursement totals`}</h2>
    <p>{`The timing and frequency of disbursement payments depend on the laws and regulations that govern the disbursement.`}</p>
    <br />
    <p>{`ONRR disburses some revenue the same month we receive the revenue, some at the end of the fiscal year, and some the following fiscal year. Because we pay some disbursements the following fiscal year, the revenue and disbursement totals for a given fiscal year may not match up.`}</p>
    <br />
    <p>{`Companies sometimes send payments to ONRR before sending the report documenting the details of the payment. Without the report, ONRR cannot account for and disburse that revenue. As a result, the revenue may show up in one fiscal year, with the disbursement coming the next fiscal year when ONRR processes the report. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      